/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Dependencias
import React, { PureComponent } from 'react';

// Hojas de Estilos
import './DocumentosRecientes.scss';

// Componentes
import Slider from 'react-slick';

const UpArrow = ({ onClick }) => (
  <div className="custom-slick-arrow">
    <a onClick={onClick} role="button" tabIndex={0} className="tw-text-black">
      <span className="far fa-arrow-alt-circle-up fa-lg" />
    </a>
  </div>
);

const DownArrow = ({ onClick }) => (
  <div className="custom-slick-arrow">
    <a onClick={onClick} role="button" tabIndex={0} className="tw-text-black">
      <span className="far fa-arrow-alt-circle-down fa-lg" />
    </a>
  </div>
);

class DocumentosRecientes extends PureComponent {
  render() {
    const slick_settings = {
      autoplay: true,
      autoplaySpeed: 4600,
      infinite: true,
      speed: 900,
      slidesToShow: 2,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      prevArrow: <UpArrow />,
      nextArrow: <DownArrow />,
    };
    const { data } = this.props;
    return (
      <div className="documentos-recientes-wrapper">
        <div className="border-extern">
          <div className="border-intern">
            <div className="documentos-recientes-content d-flex align-items-center">
              <Slider {...slick_settings}>
                {data.map((item, index) => (<a key={index} href={item.url} className="doc-link">{item.text}</a>))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentosRecientes;
