import React, { PureComponent } from 'react';

import './Index.scss';

export default class TitleSection extends PureComponent {
  render() {
    const { size, text } = this.props;
    switch (size) {
      case '2':
        return (
          <h2 className="text-center title-section">{text}</h2>
        );
      case '3':
        return (
          <h3 className="text-center title-section">{text}</h3>
        );
      case '4':
        return (
          <h4 className="text-center title-section">{text}</h4>
        );
      case '5':
        return (
          <h5 className="text-center title-section">{text}</h5>
        );
      default:
        return (
          <h1 className="text-center title-section">{text}</h1>
        );
    }
  }
}
