/* eslint-disable camelcase */
// Dependencias
import React from 'react';
import appConfig from '../../App.config';

// Hojas de Estilos
import './Evaluaciones.scss';

const Evaluaciones = ({ url_pbr, url_imco, url_itdif }) => (
  <div className="row evaluaciones-wrapper">
    <div className="col-md-4">
      <div className="evaluacion-image">
        <a href={appConfig.rootPath + appConfig.finanzas.urlBase + url_pbr}>
          <img className="evaluacion-image--normal" src={`${appConfig.rootPath + appConfig.folderAssets}/img/finanzas/evaluaciones-botones/prb.svg`} alt="PbR-SED" />
          <img className="evaluacion-image--hover" src={`${appConfig.rootPath + appConfig.folderAssets}/img/finanzas/evaluaciones-botones/pbr-presionado.svg`} alt="PbR-SED" />
          <span>PbR-SED</span>
        </a>
      </div>
      <p>Presupuesto basado en Resultados de evaluación del Desempeño.</p>
    </div>
    <div className="col-md-4">
      <div className="evaluacion-image">
        <a href={appConfig.rootPath + appConfig.finanzas.urlBase + url_imco}>
          <img className="evaluacion-image--normal" src={`${appConfig.rootPath + appConfig.folderAssets}/img/finanzas/evaluaciones-botones/imco.svg`} alt="IMCO" />
          <img className="evaluacion-image--hover" src={`${appConfig.rootPath + appConfig.folderAssets}/img/finanzas/evaluaciones-botones/imco-presionado.svg`} alt="IMCO" />
          <span>IMCO</span>
        </a>
      </div>
      <p>Instituto Mexicano para la Competitividad.</p>
    </div>
    <div className="col-md-4">
      <div className="evaluacion-image">
        <a href={appConfig.rootPath + appConfig.finanzas.urlBase + url_itdif}>
          <img className="evaluacion-image--normal" src={`${appConfig.rootPath + appConfig.folderAssets}/img/finanzas/evaluaciones-botones/itdif.svg`} alt="ITDIF" />
          <img className="evaluacion-image--hover" src={`${appConfig.rootPath + appConfig.folderAssets}/img/finanzas/evaluaciones-botones/itdif-presionado.svg`} alt="ITDIF" />
          <span>ITDIF</span>
        </a>
      </div>
      <p>Indice de transparencia y disponibilidad de la información fiscal.</p>
    </div>
  </div>
);

export default Evaluaciones;
