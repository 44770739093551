// Dependencias
import React from 'react';

// Hojas de Estilos
import './Obligaciones.scss';

const Obligaciones = ({ url }) => (
  <div className="obligaciones-wrapper">
    <h3>OBLIGACIONES DE TRANSPARENCIA DE SONORA</h3>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <button type="button" className="btn btn-danger">VISITA EL PORTAL HACIENDO CLICK AQUÍ</button>
    </a>
  </div>
);
// class Obligaciones extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     const { url } = this.props;
//     return (
//       <div className="obligaciones-wrapper">
//         <h3>OBLIGACIONES DE TRANSPARENCIA DE SONORA</h3>
//         <a href={url} target="_blank" rel="noopener noreferrer">
//           <button type="button" className="btn btn-danger">VISITA EL PORTAL HACIENDO CLICK AQUÍ</button>
//         </a>
//       </div>
//     );
//   }
// }

Obligaciones.defaultProps = {
  url: 'http://transparencia.esonora.gob.mx/Sonora/Transparencia/Poder+Ejecutivo/Secretar%C3%ADas/Secretar%C3%ADa+de+Hacienda/',
};

export default Obligaciones;
