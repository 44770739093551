// Dependencias
import React from 'react';
import appConfig from '../../App.config';

// Componentes
import TitleSection from '../../Components/Generic/TitleSection/Index';
import Timeline from '../../Components/Finanzas/Timeline';
import Evaluaciones from '../../Components/Finanzas/Evaluaciones';
import DocumentosRecientes from '../../Components/Finanzas/DocumentosRecientes';
import Obligaciones from '../../Components/Finanzas/Obligaciones';

// Hojas de Estilos
import './Index.scss';

const FinanzasIndex = ({ timeline, model }) => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        <TitleSection text="CICLO PRESUPUESTARIO" />
        <Timeline items={timeline} isHome />
      </div>
    </div>
    <div className="row evaluacion-wrapper">
      <div className="col-12 col-lg-8">
        <TitleSection text="EVALUACIONES" size="4" />
        <Evaluaciones
          url_pbr={model.url.pbr}
          url_imco={model.url.imco}
          url_itdif={model.url.itdif}
        />
      </div>
      <div className="col-12 col-lg-4">
        <div className="evaluaciones-section">
          <TitleSection text="DOCUMENTOS RECIENTES" size="4" />
          <DocumentosRecientes data={model.documentosRecientes} />
        </div>
      </div>
    </div>
    <Obligaciones url={model.url.obligaciones} />
  </div>
);

// class FinanzasIndex extends Component {
//   render() {
//     const { model, timeline } = this.props;

//     return (
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-md-12">
//             <TitleSection text="CICLO PRESUPUESTARIO" />
//             <Timeline items={timeline} isHome />
//           </div>
//         </div>
//         <div className="row evaluacion-wrapper">
//           <div className="col-12 col-lg-8">
//             <TitleSection text="EVALUACIONES" size="4" />
//             <Evaluaciones
//               url_pbr={model.url.pbr}
//               url_imco={model.url.imco}
//               url_itdif={model.url.itdif}
//             />
//           </div>
//           <div className="col-12 col-lg-4">
//             <div className="evaluaciones-section">
//               <TitleSection text="DOCUMENTOS RECIENTES" size="4" />
//               <DocumentosRecientes data={model.documentosRecientes} />
//             </div>
//           </div>
//         </div>
//         <Obligaciones url={model.url.obligaciones} />
//       </div>
//     );
//   }
// }

FinanzasIndex.defaultProps = {
  timeline: [
    {
      text: 'Planeación', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.planeacion, primaryText: true, icon: 'fas fa-lightbulb', color: 'verde-oscuro', colorHover: 'verde-oscuro', iconVisible: true, textVisible: true,
    },
    {
      text: 'Programación', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.programacion, icon: 'fas fa-calendar-alt', color: 'verde-claro', colorHover: 'verde-claro', iconVisible: true, textVisible: true,
    },
    {
      text: 'Presupuestación', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.presupuesto, primaryText: true, icon: 'fas fa-dollar-sign', color: 'blue', colorHover: 'blue', iconVisible: true, textVisible: true,
    },
    {
      text: 'Rendición de Cuentas', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.rendicionCuentas, icon: 'fas fa-file-alt', color: 'yellow', colorHover: 'yellow', iconVisible: true, textVisible: true,
    },
    {
      text: 'Evaluación', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.evaluacion, primaryText: true, icon: 'fas fa-calendar-check', color: 'marron', colorHover: 'marron', iconVisible: true, textVisible: true,
    },
    {
      text: 'Deuda', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.deuda, icon: 'fab fa-joomla', color: 'purple', colorHover: 'purple', iconVisible: true, textVisible: true,
    },
    {
      text: 'Estadisticas', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.estadisticas, primaryText: true, icon: 'fas fa-chart-bar', color: 'orange', colorHover: 'orange', iconVisible: true, textVisible: true,
    },
  ],
};

export default FinanzasIndex;
