/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Dependencias
import React, { Component } from 'react';

// Componentes

// Hojas de Estilos
import './TimelineMobile.scss';
import './Timeline.scss';

const TimelineFinanzasButton = ({ url, color, colorHover, active, textVisible, primaryText, text, iconVisible, iconClassesCss }) => (
  <a href={url} className={`timeline-button ${color} color-hover-${colorHover}${active ? ' active' : ''}`}>
    {
            (textVisible) ? (
              <p className={(primaryText) ? 'timeline-button__text primary' : 'timeline-button__text secondary'}>
                { text }
              </p>
            ) : null
        }
    {
            (iconVisible) ? (
              <span className={((primaryText) ? 'timeline-button__icon secondary ' : 'timeline-button__icon primary ') + color}>
                <i className={iconClassesCss} />
              </span>
            ) : null
        }
  </a>
);

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marginTimeline: 0,
      timelineItemActive: 0,
    };
  }

  componentDidMount() {
    const { activeIndex } = this.props;
    this.setState({
      timelineItemActive: activeIndex,
    });
  }

  render() {
    const { marginTimeline, timelineItemActive } = this.state;
    const { isHome, items, activeIndex } = this.props;
    return (
      <div className="timeline-wrapper">
        <a
          className="arrow-left"
          onClick={e => {
            e.preventDefault();
            const timeline = document.getElementById('Timeline');

            if (window.screen.width >= 992) {
              if (marginTimeline < 0) {
                timeline.setAttribute('style', `margin-left: ${marginTimeline + 5}%;`);
                this.setState({
                  marginTimeline: (marginTimeline + 5),
                });
              }
            } else if (timelineItemActive > 0) {
              this.setState({
                timelineItemActive: timelineItemActive - 1,
              });
            } else {
              this.setState({
                timelineItemActive: 0,
              });
            }
          }}
        >
          {
                    (isHome) ? (<i className="fas fa-caret-left" />) : null
                }
          {
                    (!isHome)
                      ? ((window.screen.width >= 992) ? (<i className="fas fa-caret-left" />) : (<i className="fas fa-caret-up" />)) : null
                }
        </a>
        <ul id="Timeline" className="timeline">
          {
                        items.map((item, index) => {
                          const current = index === timelineItemActive;
                          const active = index === activeIndex;
                          return (
                            <li className={(current ? 'active' : null)} key={index} id={`timelineItem${index}`}>
                              <TimelineFinanzasButton
                                text={item.text}
                                url={item.url}
                                primaryText={item.primaryText}
                                iconClassesCss={item.icon}
                                color={item.color}
                                colorHover={item.colorHover}
                                iconVisible={item.iconVisible}
                                textVisible={item.textVisible}
                                active={active}
                              />
                            </li>
                          );
                        })
                    }
        </ul>
        <a
          className="arrow-right"
          onClick={e => {
            e.preventDefault();

            if (window.screen.width >= 992) {
              if (marginTimeline > -20) {
                const timeline = document.getElementById('Timeline');
                timeline.setAttribute('style', `margin-left: ${marginTimeline - 5}%;`);
                this.setState({
                  marginTimeline: (marginTimeline - 5),
                });
              }
            } else if (timelineItemActive < (items.length - 1)) {
              this.setState({
                timelineItemActive: timelineItemActive + 1,
              });
            } else {
              this.setState({
                timelineItemActive: (items.length - 1),
              });
            }
          }}
        >
          {
                    (isHome) ? (<i className="fas fa-caret-right" />) : null
                }
          {
                    (!isHome)
                      ? ((window.screen.width >= 992) ? (<i className="fas fa-caret-right" />) : (<i className="fas fa-caret-down" />)) : null
                }
        </a>
      </div>
    );
  }
}

export default Timeline;
